<template>
  <b-row class="hp-authentication-page">
    <left-item />

    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <h1 class="mb-0 mb-sm-24" style="font-size: 38px">
            Recuperar contraseña
          </h1>
          <p class="mt-sm-8 mt-sm-0 text-black-60">
            Le enviaremos por correo electrónico instrucciones para restablecer
            su contraseña.
          </p>

          <b-form class="mt-16 mt-sm-32 mb-8">
            <b-form-group
              label="Email :"
              label-for="recoverEmail"
              class="mb-16"
            >
              <b-form-input
                v-model="searchUser.email"
                @input="validateEmail"
                :class="{ 'is-invalid': emailError }"
                type="email"
                placeholder="you@example.com"
              ></b-form-input>
            </b-form-group>

            <b-button
              type="submit"
              @click.prevent="resetUserPassword"
              variant="primary"
              class="w-100"
              :disabled="emailError || !searchUser.email"
            >
              Resetear Contraseña
            </b-button>
            <div v-if="emailError" class="invalid-feedback d-block">
              {{ emailError }}
            </div>
          </b-form>

          <div class="hp-form-info text-center">
            <span class="text-black-80 hp-text-color-dark-40 hp-caption mr-4">
              volver a
            </span>

            <b-link
              class="hp-cursor-pointer text-primary-1 hp-text-color-dark-primary-2 hp-caption"
              to="/"
            >
              Login
            </b-link>
          </div>

          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";

import LeftItem from "../LeftItem.vue";
import FooterItem from "../FooterItem.vue";
import users from "@/logic/functions/users";

export default {
  data() {
    return {
      emailError: null,
      searchUser: {
        email: "",
      },
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    LeftItem,
    FooterItem,
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.searchUser.email)) {
        this.emailError = "Por favor, ingrese un correo electrónico válido.";
      } else {
        this.emailError = null;
      }
    },
    async resetUserPassword() {
      if (!this.emailError) {
        users
          .resetPassword({
            email: this.searchUser.email,
          })
          .then((response) => {
          });
      }
    },
  },
};
</script>
